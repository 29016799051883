<template>
  <VRequisitesList
    :requisites="requisites"
  />
</template>

<script>
import VRequisitesList from '@/components/profile/VRequisitesList'
import { useRoute } from 'vue-router'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const route = useRoute()
    const store = useStore()

    onBeforeMount(async () => {
      if (route.params.id) {
        await store.dispatch('user/setUser', route.params.id)
      }
    })

    const requisites = computed(() => {
      return store.getters['user/getUser']
    })

    return {
      requisites
    }
  },
  components: {
    VRequisitesList
  }
}
</script>

<style scoped>

</style>
